/* eslint-disable no-octal-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import InputMask from 'react-input-mask'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// CSS, SCSS
import '../styles/solicite-sua-coleta.scss'
// services
import { findAddress } from '../../components/admin/services/coleta-service'

// components
// import Pagina from '../../components/Pagina/index'

// Auth
import { signUp } from '../../services/auth'

// Util
import CentroColetaJson from '../../utils/centros.json'

const CriarConta = () => {
  const intl = useIntl()
  const [ufs, setUfs] = useState([])
  const [districts, setDistricts] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [telMask, setTelMask] = useState([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
  const initialForm = {
    cidade: '',
    estado: '',
    telefone: '',
    nome: '',
    email: '',
    cep: '',
    cnpj: '',
    confirmPassword: '',
    password: ''
  }
  const [formState, setFormState] = useState(initialForm)

  const data = useStaticQuery(
    graphql`{
  pagina: contentfulPagina(path: {eq: "/criar-conta/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  img: file(relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/fuso.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 700, height: 840, layout: CONSTRAINED)
    }
  }
}
`)

  useEffect(() => {
    const keys = Object.keys(CentroColetaJson)
    setUfs(keys.map(key => ({ concat: CentroColetaJson[key].concat, id: key })))
  }, [])

  function handleStateSelected (e) {
    const center = CentroColetaJson[e.target.value]
    setDistricts(center.cidade)
    setInput('estado', e.target.value)
  }

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  const handleCreateAccount = () => {
    if (handleFormValidation()) {
      signUp(formState)
        .then(res => {
          if (res === 200) {
            navigate('/area-cliente/confirmar-conta/', { state: { email: formState.email } })
          } else {
            if (res.code === 'UsernameExistsException') {
              setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.create_account.error_message1' })}</>)
            } else {
              setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.create_account.error_message2' })}</>)
            }
          }
        })
    }
  }

  function handleFormValidation () {
    if (formState && Object.keys(formState).length < 8) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message1' })}</>)
    } else if (!validaEmail()) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message2' })}</>)
    } else if (formState.password !== formState.confirmPassword) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message3' })}</>)
    } else if (!validaCNPJ()) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message4' })}</>)
    } else if (formState.telefone.length < 12) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message5' })}</>)
    } else if (formState.cep.length < 9) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message6' })}</>)
    } else if (formState.nome.match(/[0-9]/gi)) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message7' })}</>)
    } else if (formState.password === formState.confirmPassword) {
      return validaSenha() ? setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.form_validation.error_message8' })}</>) : true
    }
    return false
  }

  async function handleCep (e) {
    const cepInput = e.target.value
    if (!cepInput.match(/\d{5}-\d{3}/)) return
    const address = await findAddress(cepInput)
    setFormState({
      ...formState,
      cep: address.cep,
      estado: address.uf || '',
      cidade: address.localidade || ''
    })
  }

  function validaEmail () {
    return !!formState.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
  }

  function validaCNPJ () {
    return !!formState.cnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/)
  }

  function validaSenha () {
    return (formState.password.length < 8 || formState.confirmPassword.length < 8)
  }

  return <>
    {/* <Pagina pagina={data.pagina} /> */}
    <section className="container-fluid full m-0">
      <div className="row">
        <div className="col-lg-4 p-0 d-lg-block d-none">
          <GatsbyImage
            image={data.img.childImageSharp.gatsbyImageData}
            alt=""
            className="w-100 h-100" />
        </div>
        <div className="col-lg-8 bg-lwart-lightblue2 p-0">
          <div className="container" style={{ padding: '35px' }}>
            <p className="text-white text-uppercase font-Spinnaker font-size-125">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.p1' })}</p>
            <p className="font-size-2 text-lwart-green3"><b className="font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.p2' })}</b></p>
            <form className="form-coleta p-0 w-100 mw-100">
              <div className="row">
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label1' })}
                    <InputMask
                      mask="99.999.999/9999-99"
                      className="mb-4 w-100"
                      name="cnpj"
                      type="text"
                      value={formState.cnpj}
                      onChange={(event) => setInput(event.target.name, event.target.value)}
                    />
                  </label>
                </div>
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label2' })}
                    <InputMask
                      mask={telMask}
                      maskPlaceholder={null}
                      className="mb-3 w-100"
                      name="telefone"
                      type="text"
                      value={formState.telefone}
                      onChange={(e) => {
                        setInput(e.target.name, e.target.value)
                        if (e.target.value.match(/\d{2}\s\d{4}-\d{5}/)) {
                          setTelMask([/\d/, /\d/, ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/])
                        } else {
                          setTelMask([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
                        }
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label3' })}
                    <input
                      className="mb-4 w-100"
                      name="nome"
                      type="text"
                      value={formState.nome}
                      onChange={(event) => setInput(event.target.name, event.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label4' })}
                    <input
                      className="mb-4 w-100"
                      name="email"
                      type="text"
                      value={formState.email}
                      onChange={(event) => setInput(event.target.name, event.target.value)}
                    />
                  </label>
                </div>
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label5' })}
                    <InputMask
                      mask="99999-999"
                      maskPlaceholder={null}
                      className="mb-4 w-100"
                      name="cep"
                      type="text"
                      value={formState.cep}
                      onChange={(event) => {
                        setInput(event.target.name, event.target.value)
                        handleCep(event)
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label6' })}
                    <select className="mb-4" name="estado" onChange={(event) => handleStateSelected(event)} value={formState.estado}>
                      <option defaultValue> -- {intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.option1' })} --</option>
                      {
                        ufs.map((uf, index) => {
                          return (
                            <option value={uf.id} key={uf.id}>
                              {uf.concat}
                            </option>
                          )
                        })
                      }
                    </select>
                  </label>
                </div>
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label7' })}
                    <InputMask
                      className="w-100 mb-3"
                      name="cidade"
                      type="text"
                      value={formState.cidade}
                      onChange={(event) => setInput(event.target.name, event.target.value)}
                    />
                    {/* <select className="mb-4" name="cidade"  value={formState.cidade}>
                      <option defaultValue> -- Selecione --</option>
                      {
                        districts.map((district, index) => {
                          const name = Object.keys(district)[0]
                          return (
                            <option value={name} key={index}>
                              {name}
                            </option>
                          )
                        })
                      }
                    </select> */}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label8' })}
                    <input
                      className="mb-4 w-100"
                      name="password"
                      type="password"
                      value={formState.password}
                      onChange={(event) => setInput(event.target.name, event.target.value)}
                    />
                  </label>
                </div>
                <div className="col-lg-5">
                  <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.label9' })}
                    <input
                      className="mb-4 w-100"
                      name="confirmPassword"
                      type="password"
                      value={formState.confirmPassword}
                      onChange={(event) => setInput(event.target.name, event.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="col-lg-10 p-0">
                {
                  !!errorMessage && (
                    <div className="d-flex bg-lwart-darkblue align-items-center justify-content-center py-2 px-4 my-3 rounded">
                      <p className="bg-danger rounded-circle text-white text-center align-self-center mb-0 mr-3" style={{ width: '25px', height: '25px' }} >!</p>
                      <p className="text-center text-white mb-0 font-weight-bold w-75">
                        {errorMessage}
                      </p>
                    </div>
                  )
                }

                <button type="button" className="border-0 rounded text-white btn-entrar btn-block py-3 mb-3 font-Spinnaker" onClick={() => handleCreateAccount()}>{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.btn1' })}</button>
              </div>
            </form>
            <div className="col-lg-10 p-0">
              <p className="text-white font-size-12 mt-2">{parse(intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.p3' }))}<span className="text-lwart-green3 font-size-1125">{intl.formatMessage({ id: 'paginas.area_cliente.criar_conta.section_criar_conta.span1' })} 0800 701 0088</span>.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default CriarConta
